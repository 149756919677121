var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{ref:"modal__yearsRange",attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Fecha(s) de cambio")]},proxy:true},{key:"content",fn:function(){return [_c('validation-observer',{staticClass:"form__validation",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirm)}}},[_c('div',{staticClass:"selectDate__modal"},[_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur a quidem id ipsum suscipit officia exercitationem earum dolores architecto. ")]),_vm._l((_vm.keys),function(key){return _c('div',{key:key.id},[_c('custom-input',{attrs:{"label":(
                    _vm.categories.find(function (ref) {
                                      var id = ref.id;

                                      return id == key.id;
}) ||
                    _vm.defaultCategories.find(function (ref) {
                                      var id = ref.id;

                                      return id == key.id;
})
                  ).name,"type":"date","rules":"required"},model:{value:(key.date),callback:function ($$v) {_vm.$set(key, "date", $$v)},expression:"key.date"}})],1)})],2),_c('div',{staticClass:"modal__buttons"},[_c('Button',{attrs:{"size":"medium","variant":"text"},on:{"click":_vm.cancel}},[_vm._v("Cancelar")]),_c('Button',{attrs:{"type":"submit","disabled":invalid,"size":"medium","variant":"primary"}},[_vm._v(" Confirmar ")])],1)])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }