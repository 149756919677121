var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-employee"},[_c('Breadcrumbs',{attrs:{"views":[{ label: 'Empleados', to: '/personas-y-puestos/empleados' }],"currentView":{ label: 'Editar Empleado' }}}),_c('h2',[_vm._v("Editar Empleado")]),_c('validation-observer',{staticClass:"form__validation",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',[_c('div',{staticClass:"form__section personal-info"},[_c('h4',[_vm._v("Información Personal")]),_c('div',[_vm._l((_vm.defaultCategories.filter(
                function (ref) {
                              var id = ref.id;

                              return !['age', 'state', 'miniumWage', 'otherIncomes'].includes(id);
}
              )),function(defaultCategory){return [(defaultCategory.type === 'select')?_c('custom-select',{key:defaultCategory.id,attrs:{"options":_vm.defaultCategoriesOptions.filter(
                    function (ref) {
                                  var categoryId = ref.categoryId;

                                  return categoryId === defaultCategory.id;
              }
                  ),"label":defaultCategory.name,"rules":"required","icon":defaultCategory.icon},model:{value:(_vm.employee[defaultCategory.id]),callback:function ($$v) {_vm.$set(_vm.employee, defaultCategory.id, $$v)},expression:"employee[defaultCategory.id]"}}):_c('custom-input',{key:defaultCategory.id,attrs:{"label":defaultCategory.name,"type":defaultCategory.id === 'email' ? 'email' : defaultCategory.type,"rules":defaultCategory.id === 'email'
                    ? 'email'
                    : defaultCategory.id === 'terminationDate'
                    ? ("min_date:" + (_vm.employee.entryDate))
                    : defaultCategory.id === 'entryDate'
                    ? ("required|min_date:" + (_vm.employee.birthDate))
                    : 'required',"icon":defaultCategory.icon},model:{value:(_vm.employee[defaultCategory.id]),callback:function ($$v) {_vm.$set(_vm.employee, defaultCategory.id, $$v)},expression:"employee[defaultCategory.id]"}})]})],2)]),_c('div',{staticClass:"form__section remuneration"},[_c('h4',[_vm._v("Remuneración")]),_c('div',[_c('div',[_c('span',[_vm._v("Sueldo Básico")]),_c('custom-input',{attrs:{"type":"number","min":"0","rules":"required|positive","icon":"money-bill"},model:{value:(_vm.employee.miniumWage),callback:function ($$v) {_vm.$set(_vm.employee, "miniumWage", $$v)},expression:"employee.miniumWage"}})],1),_c('div',{staticClass:"otherIncomes"},[_c('span',[_vm._v(" Otros Ingresos "),_c('unicon',{attrs:{"name":"plus-circle","fill":"currentColor","height":"15.5px","width":"15.5px"},on:{"click":function($event){return _vm.employee.otherIncomes.push(0)}}})],1),_c('div',_vm._l((_vm.employee.otherIncomes),function(income,index){return _c('span',{key:("otherIncomes-" + index)},[_c('custom-input',{attrs:{"min":"0","type":"number","rules":"required|positive","icon":"money-bill"},model:{value:(_vm.employee.otherIncomes[index]),callback:function ($$v) {_vm.$set(_vm.employee.otherIncomes, index, $$v)},expression:"employee.otherIncomes[index]"}}),_c('unicon',{attrs:{"name":"minus-circle","fill":"currentColor","height":"15.5px","width":"15.5px"},on:{"click":function($event){_vm.employee.otherIncomes.length > 1
                        ? _vm.employee.otherIncomes.splice(index, 1)
                        : null}}})],1)}),0)])])]),_c('div',{staticClass:"form__section indicators"},[_c('h4',[_vm._v("Indicadores")]),_c('div',_vm._l((_vm.categories.filter(function (category) { return !category.isDeletable; })),function(category){return _c('custom-select',{key:category.id,attrs:{"options":_vm.options.filter(function (option) { return option.categoryId === category.id; }),"label":category.name,"rules":"required","icon":"folder"},model:{value:(_vm.employee[category.id]),callback:function ($$v) {_vm.$set(_vm.employee, category.id, $$v)},expression:"employee[category.id]"}})}),1),_c('span',{staticClass:"separator"}),_c('div',_vm._l((_vm.categories.filter(function (category) { return category.isDeletable; })),function(category){return _c('custom-select',{key:category.id,attrs:{"options":_vm.options.filter(function (option) { return option.categoryId === category.id; }),"label":category.name,"rules":"required","icon":"folder-plus"},model:{value:(_vm.employee[category.id]),callback:function ($$v) {_vm.$set(_vm.employee, category.id, $$v)},expression:"employee[category.id]"}})}),1)])]),_c('div',{staticClass:"form__buttons"},[_c('div',[_c('Button',{attrs:{"type":"submit","disabled":invalid || _vm.isEditLoading,"size":"medium","variant":"primary"},on:{"click":function($event){_vm.isUpdate = false}}},[_vm._v(" "+_vm._s(!_vm.isUpdate && _vm.isEditLoading ? 'Guardando...' : 'Guardar')+" ")]),_c('Button',{attrs:{"type":"submit","disabled":invalid || _vm.isEditLoading,"size":"medium","variant":"primary"},on:{"click":function($event){_vm.isUpdate = true}}},[_vm._v(" "+_vm._s(_vm.isUpdate && _vm.isEditLoading ? 'Actualizando...' : 'Actualizar')+" ")])],1),_c('Button',{attrs:{"to":"/personas-y-puestos/empleados","disabled":invalid || _vm.isEditLoading,"size":"medium","variant":"text"}},[_vm._v(" Cancelar ")])],1)])]}}])}),_c('select-change-date-modal',{ref:"modal__selectDate"}),_c('confirm-dialogue',{ref:"confirmDialogue"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }