const CATEGORIES = [
  {
    id: 'state',
    name: 'Estado',
    type: 'select',
  },
  {
    id: 'documentType',
    name: 'Documento',
    type: 'select',
    icon: 'id',
  },
  {
    id: 'documentNumber',
    name: 'N° de documento',
    type: 'text',
    icon: 'id',
  },
  {
    id: 'name',
    name: 'Nombre',
    type: 'text',
    icon: 'user',
  },
  {
    id: 'gender',
    name: 'Género',
    type: 'select',
    icon: 'circle',
  },
  {
    id: 'birthDate',
    name: 'Fecha de nacimiento',
    type: 'date',
    icon: 'calender',
  },
  {
    id: 'age',
    name: 'Edad',
    type: 'text',
  },
  {
    id: 'email',
    name: 'Email',
    type: 'text',
    icon: 'envelope',
  },
  {
    id: 'entryDate',
    name: 'Fecha de ingreso',
    type: 'date',
    icon: 'calender',
  },
  {
    id: 'terminationDate',
    name: 'Fecha de cese',
    type: 'date',
    icon: 'calender',
  },
  {
    id: 'miniumWage',
    name: 'Sueldo base',
    type: 'text',
  },
  {
    id: 'otherIncomes',
    name: 'Otros Ingresos',
    type: 'text',
  },
];

const OPTIONS = [
  {
    name: 'DNI',
    id: 'DNI',
    categoryId: 'documentType',
  },
  {
    name: 'Pasaporte',
    id: 'Pasaporte',
    categoryId: 'documentType',
  },
  {
    name: 'Carné de Extranjería',
    id: 'Carné de Extranjería',
    categoryId: 'documentType',
  },
  {
    name: 'Otro',
    id: 'Otro',
    categoryId: 'documentType',
  },
  {
    name: 'Masculino',
    id: 'Masculino',
    categoryId: 'gender',
  },
  {
    name: 'Femenino',
    id: 'Femenino',
    categoryId: 'gender',
  },
  {
    name: 'Activo',
    id: 'Activo',
    categoryId: 'state',
  },
  {
    name: 'Cesado',
    id: 'Cesado',
    categoryId: 'state',
  },
];

const DEFAULT_CATEGORIES = {
  CATEGORIES,
  OPTIONS,
};

export default DEFAULT_CATEGORIES;
