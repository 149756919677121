<template>
  <div>
    <Modal size="sm" ref="modal__yearsRange">
      <template #title>Fecha(s) de cambio</template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(confirm)">
            <div class="selectDate__modal">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur a quidem id
                ipsum suscipit officia exercitationem earum dolores architecto.
              </p>
              <div v-for="key in keys" :key="key.id">
                <custom-input
                  v-model="key.date"
                  :label="
                    (
                      categories.find(({ id }) => id == key.id) ||
                      defaultCategories.find(({ id }) => id == key.id)
                    ).name
                  "
                  type="date"
                  rules="required"
                />
              </div>
            </div>

            <div class="modal__buttons">
              <Button @click="cancel" size="medium" variant="text">Cancelar</Button>
              <Button type="submit" :disabled="invalid" size="medium" variant="primary">
                Confirmar
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Modal from '@/components/Modal.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import Button from '@/components/buttons/Button.vue';
import DEFAULT_CATEGORIES from '@/defaultCategories';

export default {
  name: 'YearsRangeModal',
  components: {
    Modal,
    ValidationObserver,
    Button,
    CustomInput,
  },
  data() {
    return {
      loading: false,
      keys: null,
      defaultCategories: DEFAULT_CATEGORIES.CATEGORIES,
    };
  },

  props: {},

  methods: {
    ...mapActions(['updateCompany']),
    ...mapMutations(['setAlert']),

    confirm() {
      this.$refs.modal__yearsRange.close();
      this.resolvePromise(this.keys);
    },

    cancel() {
      this.$refs.modal__yearsRange.close();
      this.resolvePromise([]);
    },

    show(editedKeys) {
      this.$refs.modal__yearsRange.open();
      this.keys = editedKeys.map((key) => ({ id: key, date: '' }));
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
  },

  mounted() {},

  computed: {
    ...mapState({
      company: (state) => ({ ...state.company, yearsRange: state.company.yearsRange || 10 }),
      employees: (state) => state.employees.employees,
      categories: (state) => state.categories.categories,
    }),
  },
};
</script>

<style lang="scss" scoped>
.selectDate__modal {
  padding: 1.5rem 1.5rem;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

form {
  & > div {
    display: flex;
  }
}

.modal__buttons {
  span {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
</style>
