<template>
  <div class="edit-employee">
    <Breadcrumbs
      :views="[{ label: 'Empleados', to: '/personas-y-puestos/empleados' }]"
      :currentView="{ label: 'Editar Empleado' }"
    />
    <h2>Editar Empleado</h2>
    <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
      <form @submit.prevent="handleSubmit(save)">
        <div>
          <div class="form__section personal-info">
            <h4>Información Personal</h4>
            <div>
              <template
                v-for="defaultCategory in defaultCategories.filter(
                  ({ id }) => !['age', 'state', 'miniumWage', 'otherIncomes'].includes(id),
                )"
              >
                <custom-select
                  v-if="defaultCategory.type === 'select'"
                  :key="defaultCategory.id"
                  v-model="employee[defaultCategory.id]"
                  :options="
                    defaultCategoriesOptions.filter(
                      ({ categoryId }) => categoryId === defaultCategory.id,
                    )
                  "
                  :label="defaultCategory.name"
                  rules="required"
                  :icon="defaultCategory.icon"
                />
                <custom-input
                  v-else
                  :key="defaultCategory.id"
                  v-model="employee[defaultCategory.id]"
                  :label="defaultCategory.name"
                  :type="defaultCategory.id === 'email' ? 'email' : defaultCategory.type"
                  :rules="
                    defaultCategory.id === 'email'
                      ? 'email'
                      : defaultCategory.id === 'terminationDate'
                      ? `min_date:${employee.entryDate}`
                      : defaultCategory.id === 'entryDate'
                      ? `required|min_date:${employee.birthDate}`
                      : 'required'
                  "
                  :icon="defaultCategory.icon"
                />
              </template>
            </div>
          </div>
          <div class="form__section remuneration">
            <h4>Remuneración</h4>
            <div>
              <div>
                <span>Sueldo Básico</span>
                <custom-input
                  v-model="employee.miniumWage"
                  type="number"
                  min="0"
                  rules="required|positive"
                  icon="money-bill"
                />
              </div>
              <div class="otherIncomes">
                <span>
                  Otros Ingresos
                  <unicon
                    @click="employee.otherIncomes.push(0)"
                    name="plus-circle"
                    fill="currentColor"
                    height="15.5px"
                    width="15.5px"
                  ></unicon
                ></span>
                <div>
                  <span
                    v-for="(income, index) in employee.otherIncomes"
                    :key="`otherIncomes-${index}`"
                  >
                    <custom-input
                      min="0"
                      type="number"
                      v-model="employee.otherIncomes[index]"
                      rules="required|positive"
                      icon="money-bill"
                    />
                    <unicon
                      @click="
                        employee.otherIncomes.length > 1
                          ? employee.otherIncomes.splice(index, 1)
                          : null
                      "
                      name="minus-circle"
                      fill="currentColor"
                      height="15.5px"
                      width="15.5px"
                    ></unicon>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="form__section indicators">
            <h4>Indicadores</h4>
            <div>
              <custom-select
                v-for="category in categories.filter((category) => !category.isDeletable)"
                v-model="employee[category.id]"
                :key="category.id"
                :options="options.filter((option) => option.categoryId === category.id)"
                :label="category.name"
                rules="required"
                icon="folder"
              />
            </div>

            <span class="separator"></span>

            <div>
              <custom-select
                v-for="category in categories.filter((category) => category.isDeletable)"
                v-model="employee[category.id]"
                :key="category.id"
                :options="options.filter((option) => option.categoryId === category.id)"
                :label="category.name"
                rules="required"
                icon="folder-plus"
              />
            </div>
          </div>
        </div>
        <div class="form__buttons">
          <div>
            <Button
              @click="isUpdate = false"
              type="submit"
              :disabled="invalid || isEditLoading"
              size="medium"
              variant="primary"
            >
              {{ !isUpdate && isEditLoading ? 'Guardando...' : 'Guardar' }}
            </Button>
            <Button
              @click="isUpdate = true"
              type="submit"
              :disabled="invalid || isEditLoading"
              size="medium"
              variant="primary"
            >
              {{ isUpdate && isEditLoading ? 'Actualizando...' : 'Actualizar' }}
            </Button>
          </div>
          <Button
            to="/personas-y-puestos/empleados"
            :disabled="invalid || isEditLoading"
            size="medium"
            variant="text"
          >
            Cancelar
          </Button>
        </div>
      </form>
    </validation-observer>

    <select-change-date-modal ref="modal__selectDate" />

    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import CustomSelect from '@/components/custom/CustomSelect.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import Button from '@/components/buttons/Button.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import {
  timeStampToYMD, YMDToDate, YMDToDMY, YMDToTimeStamp,
} from '@/dateFormats';
import DEFAULT_CATEGORIES from '@/defaultCategories';
import SelectChangeDateModal from './modals/SelectChangeDateModal.vue';

export default {
  name: 'EditEmployee',
  components: {
    CustomInput,
    CustomSelect,
    Button,
    ValidationObserver,
    Breadcrumbs,
    SelectChangeDateModal,
    ConfirmDialogue,
  },
  data() {
    return {
      previousEmployee: {},
      employee: {},
      history: [],
      isEditLoading: false,
      timeStampToYMD,
      YMDToDate,
      YMDToDMY,
      YMDToTimeStamp,
      defaultCategories: DEFAULT_CATEGORIES.CATEGORIES,
      defaultCategoriesOptions: DEFAULT_CATEGORIES.OPTIONS,
      isUpdate: false,
    };
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('employees', ['updateEmployee']),
    ...mapActions('employees', ['fetchEmployees', 'batchDelete']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('options', ['fetchOptions']),
    ...mapMutations(['setAlert']),
    ...mapActions('history', ['addLog']),
    getOptionName(id) {
      const option = this.options.find((opt) => opt.id === id);
      return option ? option.name : '';
    },
    async save() {
      const keys = this.getEditedKeys();
      if (!keys.length) this.$router.push('/personas-y-puestos/empleados');

      const dates = this.isUpdate ? await this.$refs.modal__selectDate.show(keys) : [];

      if (this.isUpdate && !dates.length) return;

      try {
        this.isEditLoading = true;
        await this.updateEmployee({
          ...this.employee,
          birthDate: this.YMDToDate(this.employee.birthDate),
          entryDate: this.YMDToDate(this.employee.entryDate),
          terminationDate: this.employee.terminationDate
            ? this.YMDToDate(this.employee.terminationDate)
            : null,
          history: this.isUpdate
            ? this.getEmployeeHistory(keys, dates)
            : this.updateEmployeeHistory(keys),
        });
        await this.addHistory(keys);
        this.setAlert({
          state: 'success',
          message: 'Empleado editado',
        });
      } catch (error) {
        console.log(error);
        let message = 'Ocurrió un error, por favor inténtelo nuevamente';
        if (error.code === 'permission-denied') {
          message = 'Acceso denegado. No tienes acceso a estos datos.';
        }
        this.setAlert({
          state: 'error',
          message,
        });
      } finally {
        this.isEditLoading = false;
        this.$router.push('/personas-y-puestos/empleados');
      }
    },

    getEditedKeys() {
      const editedKeys = [];
      Object.keys(this.employee).forEach((key) => {
        if (
          key !== 'history'
          && ((key === 'otherIncomes'
          && this.previousEmployee[key].reduce((acc, val) => acc + val, 0)
          !== this.employee[key].reduce((acc, val) => acc + val, 0))
          || (key !== 'otherIncomes' && this.previousEmployee[key] !== this.employee[key]))
        ) {
          editedKeys.push(key);
        }
      });
      return editedKeys;
    },

    getEmployeeHistory(keys, dates) {
      const employeeHistory = this.employee.history;

      keys.forEach((key) => {
        const time = this.YMDToTimeStamp(dates.find(({ id }) => id === key).date).seconds;
        if (!employeeHistory[key]) employeeHistory[key] = {};
        if (['birthDate', 'entryDate', 'terminationDate'].includes(key)) {
          employeeHistory[key][time] = this.YMDToDate(this.employee[key]);
        } else employeeHistory[key][time] = this.employee[key];
      });
      return employeeHistory;
    },

    updateEmployeeHistory(keys) {
      const employeeHistory = this.employee.history;

      keys.forEach((key) => {
        let historyKeys = [];
        let lastUpdate = 0;
        if (!this.employee.history[key]) {
          lastUpdate = this.YMDToTimeStamp(this.employee.entryDate).seconds;
        } else {
          historyKeys = Object.keys(this.employee.history[key]);
          [lastUpdate] = historyKeys.sort((a, b) => (a < b ? 1 : -1));
        }
        if (!employeeHistory[key]) employeeHistory[key] = {};
        if (['birthDate', 'entryDate', 'terminationDate'].includes(key)) {
          employeeHistory[key][lastUpdate] = this.YMDToDate(this.employee[key]);
        } else employeeHistory[key][lastUpdate] = this.employee[key];
      });
      return employeeHistory;
    },

    async addHistory(keys) {
      let content = `Empleado ${this.isUpdate ? 'actualizado' : 'editado'}:\nNombre: ${
        this.employee.name
      }\nNúmero de documento: ${this.employee.documentNumber}\n\nCategorias editadas:\n`;
      keys.forEach((key) => {
        const category = [
          ...this.categories,
          ...this.defaultCategories.map((cat) => ({ ...cat, isDefault: true })),
        ].find(({ id }) => id === key);
        content += `${category.name}: `;
        if (category.isDefault) {
          if (['birthDate', 'entryDate', 'terminationDate'].includes(key)) {
            content += `${this.YMDToDMY(this.previousEmployee[key])} --> ${this.YMDToDMY(
              this.employee[key],
            )}\n`;
          } else if (key === 'otherIncomes') {
            const previousOtherIncomes = this.previousEmployee[key].reduce(
              (acc, val) => acc + val,
              0,
            );
            const otherIncomes = this.employee[key].reduce((acc, val) => acc + val, 0);
            content += `${previousOtherIncomes} --> ${otherIncomes}\n`;
          } else {
            content += `${this.previousEmployee[key] || 'No ingresado'} --> ${
              this.employee[key] || 'No ingresado'
            }\n`;
          }
        } else {
          content += `${this.getOptionName(this.previousEmployee[key])} --> ${this.getOptionName(
            this.employee[key],
          )}\n`;
        }
      });

      await this.addLog({
        authorId: this.userProfile.id,
        createdOn: new Date(),
        authorName: this.userProfile.name,
        action: this.isUpdate ? 'Actualizar' : 'Editar',
        content,
      });
    },
  },

  computed: {
    ...mapState({
      userProfile: (state) => state.userProfile,
      employees: (state) => state.employees.employees,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
  },

  async mounted() {
    try {
      if (!this.employees.length) await this.fetchEmployees();
      if (!this.categories.length) await this.fetchCategories();
      if (!this.options.length) await this.fetchOptions();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.setAlert({
        state: 'error',
        message: 'Ocurrió un error al cargar los datos, por favor inténtelo nuevamente',
      });
    }
    this.employee = this.employees.find((employee) => employee.id === this.$route.params.id);
    this.previousEmployee = JSON.parse(JSON.stringify(this.employee));
    this.employee = {
      ...this.employee,
      birthDate: this.timeStampToYMD(this.employee.birthDate),
      entryDate: this.timeStampToYMD(this.employee.entryDate),
      terminationDate: this.employee.terminationDate
        ? this.timeStampToYMD(this.employee.terminationDate)
        : null,
    };
    this.previousEmployee = {
      ...this.previousEmployee,
      birthDate: this.timeStampToYMD(this.previousEmployee.birthDate),
      entryDate: this.timeStampToYMD(this.previousEmployee.entryDate),
      terminationDate: this.previousEmployee.terminationDate
        ? this.timeStampToYMD(this.previousEmployee.terminationDate)
        : null,
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-employee {
  height: 100%;
  display: flex;
  flex-flow: column;
  h2 {
    margin-bottom: 0.15em;
  }

  .employees-nav {
    display: flex;
    gap: 0.5em;
    align-items: center;
    cursor: pointer;
    p {
      font-size: 0.83em;
      color: var(--font-color-100);
      font-weight: var(--regular);
      margin-bottom: 1em;
      letter-spacing: 0.4px;
      &:nth-child(2) {
        height: 2.09em;
      }

      &:first-child:hover,
      &:nth-child(3):hover {
        color: var(--font-color-700);
      }
    }
    & > p:last-child {
      font-weight: var(--medium);
      color: var(--font-color-700);
    }
  }
  form {
    height: 100%;
    display: flex;
    flex-flow: column;
    & > div {
      margin-top: 1em;
    }

    & > div:first-child {
      overflow: auto;
    }

    button {
    }
  }

  .form__validation {
    overflow: hidden;
  }

  .form__section {
    width: 100%;
    & > div {
      padding: 1rem 0;
      gap: 1rem;
      display: grid;
    }

    &.personal-info {
      grid-area: personal-info;
      & > div {
        grid-template-columns: repeat(3, auto);
        padding-right: 0.5rem;
      }
    }

    &.indicators {
      grid-area: indicators;
      display: flex;
      flex-flow: column;
      & > div {
        grid-template-columns: repeat(3, auto);
        padding-right: 0.5rem;
      }
    }

    &.remuneration {
      grid-area: remuneration;
      .input {
        width: 8rem;
      }
      .unicon {
        display: flex !important;
        align-items: center;
        cursor: pointer;
      }

      & > div {
        display: flex;
        div > span {
          display: flex;
          gap: 0.5em;
          color: var(--font-color-300);
          font-size: 0.875rem;
          font-weight: var(--medium);
          margin-bottom: 0.5em;
          .unicon {
            color: var(--main-color-500);
          }
        }
      }

      .otherIncomes {
        width: 88%;
        & > div {
          overflow-x: auto;
          display: flex;
          flex-wrap: nowrap;
          gap: 1em;

          span {
            display: flex;
            gap: 0.5em;
            .unicon {
              color: var(--danger-color-500);
            }
          }
          .input {
            min-width: 5em;
          }
        }
      }
    }
  }

  .form__buttons {
    display: flex;
    margin-top: 0;
    padding-top: 1rem;
    border-top: solid 2px var(--gray-color-200);
    gap: 0.5rem;
    div {
      display: flex;
      gap: 2rem;
    }
  }
}

.separator {
  background-color: var(--gray-color-200);
  height: 1px;
}
</style>
