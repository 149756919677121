<template>
  <transition name="modal" appear>
    <div v-if="isOpen" class="modal__mask">
      <div v-click-outside="cancel" class="modal__container" :class="sizeClass">
        <button class="axis" type="button" @click="cancel">
          <unicon name="times" fill="currentColor"></unicon>
        </button>
        <div>
          <h1>
            {{ title }}
          </h1>
          <p>{{ message }}</p>
          <div class="modal__buttons">
            <Button type="button" @click="cancel" size="small" variant="text">{{
              cancelButtonText
            }}</Button>
            <Button
              type="button"
              @click="confirm"
              size="small"
              :variant="isDestructive ? 'danger' : 'primary'"
            >
              {{ okButtonText }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';
import Button from '@/components/buttons/Button.vue';

export default {
  name: 'ConfirmDialogue',
  components: {
    Button,
  },

  data: () => ({
    isOpen: false,
    title: '',
    message: '',
    isDestructive: false,
    okButtonText: '',
    cancelButtonText: 'Cancelar',

    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  props: {
    size: {
      default: 'sm',
      type: String,
    },
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.isDestructive = opts.isDestructive;
      this.okButtonText = opts.okButtonText;
      if (opts.cancelButtonText) this.cancelButtonText = opts.cancelButtonText;

      this.isOpen = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    confirm() {
      this.isOpen = false;
      this.resolvePromise(true);
    },

    cancel() {
      this.isOpen = false;
      this.resolvePromise(false);
    },
  },

  computed: {
    sizeClass() {
      switch (this.size) {
        case 'lg':
          return 'modal--lg';

        case 'md':
          return 'modal--md';

        case 'sm':
          return 'modal--sm';

        default:
          return 'modal--sm';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__container {
  max-height: 80%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.25em;
  overflow-y: hidden;
  position: relative;

  &.modal--sm {
    width: 480px;
  }
  &.modal--md {
    width: 600px;
  }
  &.modal--lg {
    width: 80%;
  }
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 300ms;
}

.modal-enter-to {
  opacity: 1;
}

.axis {
  z-index: 1;
  width: fit-content;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.modal__container > div {
  height: 100%;
  display: flex;
  flex-flow: column;

  h1 {
    padding: 1rem 1.4rem;
    padding-bottom: 0;
    font-size: 1.2rem;
    flex-grow: 1;
    font-weight: 500;
  }

  p {
    padding: 1rem 1.4rem 0.5rem 1.4rem;
    flex-grow: 1;
  }

  .modal__buttons {
    padding: 0.8rem 1rem;
    gap: 1rem;
    display: flex;
    justify-content: right;
    background-color: var(--gray-color-100);
  }
}
</style>
